@import '../../../../containers/variables.scss';
.navLink {
    display: inline-block;
    cursor: pointer;
    // max-height: 50%;

    &:not(:first-child){
        margin-right: 9rem;
        cursor: pointer;
        @media only screen and (max-width: $bp-largest){
            margin-right: 9rem;
        }
        @media only screen and (max-width: $bp-large){
            margin-right: 4.5rem;
            // display: none;
        }
        @media only screen and (max-width: $bp-medium){
            display: none;
        }
    }
    &:last-child{
        @media only screen and (max-width: $bp-largest){
            display: none;
        }
    }
    &:first-child {
        &>a{
            background-color: $callToAction;
            color: white !important;
            text-align: center;
            // padding: 1.5rem;
            line-height: 4rem;
            border-radius: 2rem;
            cursor: pointer;
            width: 16rem;
    
            @media only screen and (max-width: $bp-largest){
                width: 16rem;
            }
            @media only screen and (max-width: $bp-large){
                // display: none;
                margin-right: 6vw;
            }
            @media only screen and (max-width: $bp-small){
                background-color: white;
                color: $callToAction !important;
                margin-right: unset;
            }
            @media only screen and (max-width: $bp-small){
                background-color: white;
                color: $callToAction !important;
                margin-right: unset;
                display: none;
            }
            text-transform: uppercase;
            font-weight: 700;
    
            &>a{
                color: white;
                text-transform: uppercase;
                &:hover{
                    color:white;
                }
            }
    
            &:hover{
                background-color: darken($callToAction, 10%);
            }
        }

    }
}
.navLink > a{
    text-decoration: none;
    font-size: 1.5rem;
    color: black;
    text-transform: capitalize;

    &:hover, &:active{
        color: $callToAction
    }
    display: inline-block;

    @media only screen and (max-width: $bp-small){
        font-size: 1rem;
    }

    .active{
        color: $callToAction;
    }
}