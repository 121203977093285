  @import './variables.scss';


  *,
  *::before,
  *::after{
      margin: 0;
      padding: 0;
      box-sizing: inherit;
  }


  html{
      box-sizing: border-box;
      font-size: 62.5%;

      // @media only screen and (max-width: $bp-desktop){
      //   font-size: 50%;
      // }
      // @media only screen and (max-width: $bp-largest){
      //     font-size: 50%;
      // }
      // @media only screen and (max-width: $bp-large){
      //     font-size: 50%;
      // }
      // @media only screen and (max-width: $bp-small){
      //     font-size: 62.5%;
      // }


  }

@import './variables.scss';

.App {
  display: grid;
  grid-template-rows: min-content min-content;
  justify-content: center;
  border-radius: 1.5rem;
}


