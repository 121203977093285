@import "../../../containers/variables.scss";
.question{
    border-bottom: 1px solid rgba($color: #2539811C, $alpha: 0.1);
    border-radius: 1.5rem;
    overflow: hidden;
    padding-left: 0.1rem ;
    padding-right: 0.1rem;
    // padding-bottom: 2.3rem;

    &:not(:first-child){
        margin-top: 2rem;
    }
    button{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .text{
            display: inline-block;
            font-size: 1.5rem;
            max-width: 75%;
        }
        .icon{
            display: inline;
        }
    }

    .collapsible {
        background-color: #ffffff;
        cursor: pointer;
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
        @media only screen and (max-width: $bp-small){
            font-size: 1.2rem;
        }
        font-size: 1.8rem;
        line-height: 2.7rem;
        color: #131F4A;
        padding: 2.5rem;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
      }
      /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
        .active, .collapsible:hover {
            // background-color: #ccc;
        }
        
        /* Style the collapsible content. Note: hidden by default */
        .content {
            padding: 0 18px;
            max-height: 0;
            overflow: hidden;
            color: #131F4AB9;
            max-width: 90%;

            .answer{
                font-family: 'Open Sans', sans-serif;
                // padding-bottom: 2.3rem;
                font-size: 1.5rem;
                padding-left: 2rem;
                @media only screen and (max-width: $bp-large){
                    font-size: 1.5rem;
                }
                line-height: 2.8rem;
            }
            // background-color: #f1f1f1;
            transition: all 0.3s ease-in-out;

        }
        .closed{
            max-height: 0;
        }
        .open{
            font-weight: 400;
            max-height: 25rem;
            padding-bottom: 2.3rem;
        }
}