@import "../../containers/variables.scss";

footer{
    grid-row: 6;
    grid-column: full-start / full-end;
    background-color: #FCFCFC;
    height: 29rem;
    margin-top: 17.3vh;
    padding: 4rem 14rem;

    display: grid;
    grid-template-rows: min-content min-content;
    grid-template-rows: 1fr 1fr;

    @media only screen and (max-width: $bp-largest){
        grid-row: 7;
        grid-column: full-start / full-end;
        padding: 4rem 4rem;

    }
    @media only screen and (max-width: $bp-small){
        padding: 4rem 1rem;

    }
    .logos{
        grid-row: 1;
        grid-column: 1;

        @media only screen and (max-width: $bp-large){
            grid-column: 1/3;    
            justify-content: center;
        }

        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;

        img{
            &:not(:last-child){
                margin-right: 4vw;
            }
        }
    }
    .license{
        grid-row: 2;
        grid-column: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .credits{
        grid-row: 2;
        grid-column: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .footer__text{
            text-align: right;
        }
    }
    .footer__text{
        font-size: 1.5rem;
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
        color: rgba($color: #000000, $alpha: 0.5);

    }
}