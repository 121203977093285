@import "../../../containers/variables.scss";
        .form__element{
            display: flex;
            flex-direction: column;

            // @media only screen and (max-width: $bp-large){
            //     margin       
            // }
            @media only screen and (max-width: $bp-small){
                width: 100%;
            }

            label{
                color: $headingColor;
                margin-bottom: 0.5rem;
                font-family: 'Poppins Sans', sans-serif;
                font-weight: 700;
                font-size: 1.5rem;
                line-height: 2.4rem;

            }
            input{
                background-color: rgba($color: #F8F7F7, $alpha: 1);
                line-height: 4rem;
                border: none;
                border-radius: 0.5rem;
                padding-left: 2rem;
            }
            input[type="file"]{
                display: none;
            }
            .file__label{
                height: 4rem;
                width: 100%;
                display: flex;
                margin-bottom: 4rem;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border-radius: 0.5rem;
                background-color: #F7F7F7;
                padding: 2rem;
                transition: background-color 0.3s ease-in;

                &:hover{
                    background: rgb(255,255,255);
                    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(242,242,242,1) 100%);
                }

                .upload__icon{
                    margin-right: 2rem;
                }
                .label__text{
                    color: black;
                    font-size: 1.2rem;
                }
            }
            .file-active{
                border: solid 1px lightgreen !important;

                .upload__icon{
                    color: green;
                }
            }
            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            }

            /* Firefox */
            input[type=number] {
            -moz-appearance: textfield;
            }
            .invalid{
                border-bottom: red 1px solid !important;
            }
            .validation__error {
                color: red;
                margin: 0.5rem 0;
            } 
        }
            // label{
            //     color: $headingColor;
            //     margin-bottom: 0.5rem;
            //     font-family: 'Poppins Sans', sans-serif;
            //     font-weight: 700;
            //     font-size: 1.5rem;
            //     line-height: 2.4rem;

            // }
            // input{
            //     background-color: rgba($color: #F8F7F7, $alpha: 1);
            //     line-height: 4rem;
            //     border: none;
            //     border-radius: 0.5rem;
            //     padding-left: 2rem;
            // }