@import '../../containers/variables.scss';
#section--offer{
    grid-row: 3;
    grid-column: content-start / content-end;
    margin: 0rem 3rem 0 3rem;
    padding-top: 12vh;


    @media only screen and (max-width: $bp-largest){
        grid-row: 4;
        grid-column: full-start / full-end;
    }
    @media only screen and (max-width: $bp-large){
        grid-row: 4;
        grid-column: full-start / full-end;
    }
    @media only screen and (max-width: $bp-medium){
        grid-row: 4;
        grid-column: content-start / content-end;
    }
    @media only screen and (max-width: $bp-small){
        grid-row: 4;
        grid-column: content-start / content-end;
        padding-top: 6vh;
        // margin-left: 0;
    }


    .title--center{
        text-align: center;
        font-size: 3.5rem;
        @media only screen and (max-width: $bp-small){
            font-size: 2rem;
            margin-bottom: unset;
        }
    }
    .caption--center{
        // display: inline-block;
        // max-width: 80vw;
        text-align: center;
        margin-top: 1.2vh;
        font-size: 1.5rem;
        @media only screen and (max-width: $bp-small){
            font-size: 1.4rem;
            margin-top: unset;
        }
    }
    .underline{
        width: 6rem;
        content: "";
        height: 3px;
        background-color: $secondaryTextColor;
        margin: 2.8vh auto 5.2vh auto;

        @media only screen and (max-width: $bp-large){
            margin-bottom: 4.6rem;
        }
    }

    .offerGrid{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media only screen and (min-width: $bp-large){
            flex-direction: row;  
        }

        .laptop{
            display: flex;
            flex-direction: column;

            @media only screen and (min-width: $bp-small){
                flex-direction: row;   
                max-width: 70vw;  
            }
            @media only screen and (min-width: $bp-large){
                flex-direction: row;
                max-width: 80vw;
                margin-left: unset;
            }
            @media only screen and (min-width: $bp-largest){
                flex-direction: row;
                max-width: 80vw;
            }

            @media only screen and (max-width: $bp-large){
                // flex-direction: column; 

                align-items: center;
                max-width: 80vw;
            }
            @media only screen and (max-width: $bp-medium){
                flex-direction: column; 
                align-items: center;
                max-width: 80vw;

            }

            background-color: #F7F7F7;
            border-radius: 3rem;

            position: relative;
            padding: 2.7rem;



            .laptop--holder{ 
                // width: 100%;

                @media only screen and (min-width: $bp-small){
                    // width: 70%;
                    width: 60%;

                }
                @media only screen and (min-width: $bp-medium){
                    // width: 70%;
                    width: 60%;
                    transform: translateX(-30%);

                }
                @media only screen and (min-width: $bp-large){
                    width: 75%;
                }
                @media only screen and (min-width: $bp-largest){
                    width: 70%;

                }
                @media only screen and (max-width: $bp-small){
                    width: 90%;
                    // transform: translateY(-40%);
                }
                height: auto;

                position: absolute;
            }
            .details{

                // @media only screen and (min-width: $bp-medium){
                //     margin-left: 50%;     
                // }
                // @media only screen and (max-width: $bp-medium){
                //     margin-left: unset;
                //     margin-top: 20vh;       
                // }
                // @media only screen and (min-width: $bp-medium){
                //     margin-left: 25vw;     
                // }

                // @media only screen and (min-width: $bp-large){
                //     margin-left: 50%;     
                // }
                //sdfsdfs
                @media only screen and (min-width: $bp-small){
                    margin-left: unset;
                    margin-top: 15rem;  
                }
                @media only screen and (min-width: $bp-medium){
                    margin-top: unset;
                    margin-left: 50%;
                }
                @media only screen and (min-width: $bp-largest){
                    margin-top: unset;
                    margin-left: 50%;

                }
                @media only screen and (max-width: $bp-small){
                    margin-left: unset;
                    margin-top: 15rem;  
                }


                h3{
                    font-family: 'Poppins', sans-serif;
                    font-size: 1.8rem;
                    line-height: 2.2rem;

                    @media only screen and (min-width: $bp-large){
                        font-size: 2.5rem;
                        line-height: 3.8rem; 
                    }
                    @media only screen and (max-width: $bp-small){
                        font-size: 1.8rem;
                        line-height: 2.7rem;
                    }
                }
                p{
                    font-family: 'Open Sans', sans-serif;
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    margin-top: 1.4rem;

                    @media only screen and (min-width: $bp-large){
                        font-size: 1.5rem;
                        line-height: 2.8rem; 
                    }
                    @media only screen and (max-width: $bp-small){
                        font-size: 1.3rem;
                    }
                }
                .specs{
                    display: grid;
                    grid-template-rows: repeat(2, max-content);
                    grid-template-columns: repeat(2, max-content);
                    grid-gap: 1rem;
                    margin-top: 1.8rem;
                    .spec{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        grid-auto-rows: min-content;

                        .dot{
                            height: 5px;
                            width: 5px;
                            background-color: red;
                            border-radius: 50%;
                        }
                        .text{
                            font-family: 'Open Sans', sans-serif;
                            font-size: 1.4rem;
                            line-height: 2.8rem;
                            font-weight: 700;
                            display: inline;
                            margin-left: 1rem;
                        }
                    }
                }
                .price{
                    margin-top: 4.6vh;
                    &--real{
                        color: $secondaryTextColor;
                        font-size: 2rem;
                        line-height: 3rem;
                        @media only screen and (max-width: $bp-small){
                            font-size: 1.5rem;
                            line-height: 2.3rem;
                        }
                        font-weight: 300;
                        text-decoration: line-through;
                        display: inline-block;
                        margin-right: 3.6rem;
                    }
                    &--offer{
                        color: $callToAction;
                        font-size: 3rem;
                        line-height: 3rem;
                        @media only screen and (max-width: $bp-small){
                            font-size: 2rem;
                            line-height: 3rem;
                        }
                        display: inline-block;
                        margin-top: 1.12rem;
                    }
                }
            }
        }

    }

}

