.notification{
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 63.4rem;
    height: 41.8rem;
    -webkit-box-shadow: 0px 0px 6px 0px rgba(50, 50, 50, 0.3);
    -moz-box-shadow:    0px 0px 6px 0px rgba(50, 50, 50, 0.3);
    box-shadow:         0px 0px 6px 0px rgba(50, 50, 50, 0.3);
    border-radius: 1rem;
    padding: 2rem 4rem;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    left:0;
    right:0;
    top: 50%;
    transform: translateY(-50%);
    margin-left:auto;
    margin-right:auto;

    &__message{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #131F4A;

        img{
            margin-bottom: 3.26vh;
        }

        .message{
            font-family: 'Poppins', sans-serif;
            font-size: 1.6rem;
            line-height: 2.5rem;
            text-align: center;
            margin-top: 6.4rem;
            

            &--light{
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                font-size: 1.5rem;
                line-height: 2rem;
                text-align: center;
                color: #FF9900;
                margin-bottom: 4.2vh;
            }

            &--dark{
                color: #131F4A;
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                font-size: 2rem;
                line-height: 2rem;
                text-align: center;
            }
        }
        .icon{
            color: green;
            font-size: 1.5rem;
        }
    }
}