@import '../../containers/variables.scss';
.right{
    grid-row: 2;
    grid-column: col-start 8 / -1;
    @media only screen and (max-width: $bp-large){
        grid-row: 2;
        grid-column: full-start / full-end;
    }

    height: 100%;
    // visibility: hidden;
    .hero{
        height: 100%;
        // background:url('https://firebasestorage.googleapis.com/v0/b/dell-2ffd2.appspot.com/o/images%2Fstudentwebp.webp?alt=media&token=c6116ee4-2044-4243-a184-1b072d7f9c0f');
        background: url('../../assets/student.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        position: relative;
        padding: 6vh 5vw;
        z-index: -1;

        img{

        }

        @media only screen and (max-width: $bp-large){
            background:url('../../assets/studentsmall.jpg');
            background-blend-mode: overlay;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        @media only screen and (max-width: $bp-medium){
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .doukaniLogo{
            position: absolute;
            bottom: 4vw;
            right: 5vw;
            @media only screen and (max-width: $bp-large){
                display: none;
            }
        }


        .laptop{
            img{
                width: 40vw;
                z-index: 2;
                transform: translateX(-12vw) scale(1.125) rotate(0deg);
                -moz-transform: translateX(-12vw) scale(1.125) rotate(0deg);
                // background-image: url('../../assets/laptopcircle937.png');
                // border: 1px solid blue;
                @media only screen and (max-width: $bp-large){
                    min-width: 80vw;
                    transform: translate(0, 20%) scale(1) rotate(0deg);
                    -moz-transform: translate(0, 20%) scale(1) rotate(0deg);
                }
                @media only screen and (max-width: $bp-medium){
                    min-width: 100vw;
                    height: auto;
                    transform:translate(0, 20%) scale(1);
                    -moz-transform: translate(0, 20%) scale(1);;
                }
                @media only screen and (max-width: $bp-small){
                    // max-width: 70vw;
                    height: auto;
                    // transform: translate(-28vw,-30vh) scale(1);
                }
            }
            @media only screen and (max-width: $bp-large){
                // order: 1;
            }

            // .lapimg{
            //     // width: 78rem;
            //     width: 40vw;
            //     transform:translateX(8vw) scale(1.125) rotate(0deg);
            //     @media only screen and (max-width: $bp-largest){
            //         transform: translateX(-8vw) scale(1) rotate(-10deg);
            //     }
            //     @media only screen and (max-width: $bp-large){
            //         min-width: 70vw;
            //         max-width: 70vw;
            //         height: auto;
            //         transform: translate(-5vh,-15vh);
            //     }
            //     @media only screen and (max-width: $bp-medium){
            //         min-width: 80vw;
            //         max-width: 80vw;
            //         height: auto;
            //         transform: translate(-5vh,-25vh);
            //     }
            //     @media only screen and (max-width: $bp-small){
            //         min-width: 90vw;
            //         max-width: 90vw;
            //         height: auto;
            //         transform: translate(-5vh,-20vh);
            //     }
            // }
            .circle{
                display: none;
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                transform: scale(3) translate(28vw, -5vh);
                
            }
        }

        // min-width: 68rem;
    }
}