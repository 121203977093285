@import "../../containers/variables.scss";
img{
    width: 15rem;

    @media only screen and (max-width: $bp-large){
        width: 12rem;
    }
    @media only screen and (max-width: $bp-small){
        width: 9rem;
    }
    float: left;

    &:last-child{
        transform: translateY(0.3rem);;
    }
}

.logo{
    grid-row: 1;
    grid-column: content-start / col-end 3;

    @media only screen and (min-width: $bp-large){
        // padding-left: 5rem;
        grid-column: content-start;
    }
    @media only screen and (max-width: $bp-large){
        grid-column: content-start/ col-end 2;

    }
    @media only screen and (max-width: $bp-medium){
        grid-column: content-start;
    }
    @media only screen and (max-width: $bp-small){
        grid-column: col-start 1;

    }
    display: flex;
    flex-direction: row;
    align-items: center;
}
.logo-divider{
    padding: 0 2rem;
    font-size: 2rem;
    color: rgba($color: #000000, $alpha: 0.3);
}