@import '../../../containers/variables.scss';

.wrapper{
    min-width: 85%;
}
.newRegistration{

    grid-row: 3;
    padding-bottom: 8rem;
    grid-column: full-start / col-end 7;
    // background-image: url("https://firebasestorage.googleapis.com/v0/b/dell-2ffd2.appspot.com/o/images%2Fbackground.svg?alt=media&token=712e8bd8-9915-4bc1-b98c-9a049dfd7ce1");
    padding-top: 6.5vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media only screen and (min-width: $bp-medium){
        grid-row: 2;
    }
    @media only screen and (max-width: $bp-largest){
        margin-top: 6vh;
        padding: 3vh;
    }
    @media only screen and (max-width: $bp-large){
        grid-row: 3;
        grid-column: full-start / full-end;
        flex-direction: column;
        margin-top: 6vh;
        padding: 3vh;
    }

    
    .form{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
        grid-auto-rows: auto;
        grid-auto-columns: auto;

        grid-row-gap: 4rem;
        grid-column-gap: 9.4rem;

        &__element{
            display: flex;
            flex-direction: column;

            // @media only screen and (max-width: $bp-large){
            //     margin       
            // }
            @media only screen and (max-width: $bp-small){
                width: 100%;
            }

            label{
                color: $headingColor;
                margin-bottom: 0.5rem;
                font-family: 'Poppins Sans', sans-serif;
                font-weight: 700;
                font-size: 1.5rem;
                line-height: 2.4rem;

            }
            input{
                background-color: rgba($color: #F8F7F7, $alpha: 1);
                line-height: 4rem;
                border: none;
                border-radius: 0.5rem;
                padding-left: 2rem;
            }
            
        }

    }
    .button{
        background-color: $callToAction;
        color: white !important;
        text-align: center;
        padding: 1rem;
        border-radius: 9px;
        border: none;
        cursor: pointer;
        width: 24.4rem;
        margin-top: 1.8vh;
        
        @media only screen and (min-width: $bp-large){
            font-size: 2rem;
            line-height: 3rem;
            font-family: 'Poppins', sans-serif;
            font-weight: 700;
            // width: 26rem;
            padding: 2rem 9;
        }
        @media only screen and (max-width: $bp-small){
            margin: 0 auto;
        }
        @media only screen and (max-width: $bp-small){
            margin-top: 2.4rem;
        }

        // &:disabled{
        //     background-color: grey;
        // }

        margin-top: 2vh;

        &>a{
            color: white;
            text-decoration: none;
            font-size: 2rem;
            text-transform: uppercase;
        }

        &:hover{
            background-color: darken(red, 10%);
        }
    }
}
h2{
    color: $headingColor;
    font-size: 3.5rem;
    font-family: 'Poppins', sans-serif;
    line-height: 5.3rem;
    margin-bottom: 2vh;
}

.tagline{
    color: $headingColor;
    font-family: 'Poppins Sans', sans-serif;
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 2.4rem;
}
.underline{
    width: 6rem;
    content: "";
    height: 3px;
    background-color: $secondaryTextColor;
    margin: 2.8vh auto 5.2vh 0;
}
.declaration{
    margin: 2rem auto;
    font-family: 'Poppins Sans', sans-serif;
    font-weight: 300;
    font-size: 1rem;
    line-height: 2rem;
    display: inline-block;
    max-width: 24.4rem;
}