@import "../../containers/variables.scss";
.faq__section{
    padding: 12vh 0;
    grid-row: 5;
    grid-column: content-start / content-end;

    @media only screen and (max-width: $bp-largest){
        grid-row: 6;
        grid-column: content-start / content-end;
    }
    @media only screen and (max-width: $bp-large){
        grid-column: full-start/full-end;
        padding-top: 12vh;
        padding-bottom: 12vh;
        margin: 0 2rem;
    }

    .title--center{
        text-align: center;
        font-size: 3.5rem;
        @media only screen and (max-width: $bp-small){
            font-size: 2rem;
            margin-bottom: unset;
        }
    }
    .caption--center{
        // display: inline-block;
        // max-width: 80vw;
        text-align: center;
        margin-top: 1.2vh;
        font-size: 1.5rem;
        @media only screen and (max-width: $bp-small){
            font-size: 1.6rem;
            margin-top: unset;
        }
    }
    .underline{
        width: 6rem;
        content: "";
        height: 3px;
        background-color: $secondaryTextColor;
        margin: 2.8vh auto 5.2vh auto;

        @media only screen and (max-width: $bp-large){
            margin-bottom: 4.6rem;
        }
    }

    .faq{  
        display: grid;
        grid-template-columns: 25vw 1fr;
        grid-gap: 13rem;
        @media only screen and (max-width: $bp-large){
            grid-template-columns: 1fr;
        }
        .faq__image{
            // height: 75vh;
            width: 25vw;
            // background-position: center;
            // background-repeat: no-repeat;
            @media only screen and (max-width: $bp-large){
                display: none;
            }
        }
    
        .faq__content{

            @media only screen and (min-width: $bp-largest){
                // padding-right: 12rem;
            }
        }
    }
    
}
