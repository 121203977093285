@import '../../containers/variables.scss';
#section--how-to{
    grid-row: 4;
    grid-column: full-start / full-end;
    margin: 15.7vh 0rem 0 0rem;

    

    @media only screen and (max-width: $bp-largest){
        grid-row: 5;
        grid-column: full-start / full-end;
    }
    @media only screen and (max-width: $bp-large){
        grid-row: 5;
        grid-column: full-start / full-end;
    }

    .title--center{
        text-align: center;
        font-size: 3.5rem;
        @media only screen and (max-width: $bp-small){
            font-size: 2rem;
            margin-bottom: unset;
        }
    }
    .caption--center{
        // display: inline-block;
        // max-width: 80vw;
        text-align: center;
        margin-top: 1.2vh;
        font-size: 1.5rem;
        @media only screen and (max-width: $bp-small){
            font-size: 1.4rem;
            padding: 0 2.5rem;
            margin-top: unset;
        }
    }
    .underline{
        width: 6rem;
        content: "";
        height: 3px;
        background-color: $secondaryTextColor;
        margin: 2.8vh auto 5.2vh auto;

        @media only screen and (max-width: $bp-large){
            margin-bottom: 4.6rem;
        }
    }
    .doukani__link{
        color: $callToAction;
        cursor: pointer;
    }


    .banner{
        width: 100%;
        height: 45.2vh;
        @media only screen and (max-width: $bp-small){
            height: 24.7rem;
        }
        background-image: url('../../assets/dotbg.svg');
    
        picture{
            img{
                margin-left: 50%;
                transform: translateX(-50%);
                width: 85.6rem;
                height: 45.2rem;
    
                @media only screen and (max-width: $bp-large){
                    max-width: 80vw;
                    height: auto;
                }
                    
                @media only screen and (max-width: $bp-small){
                    min-width: 100vw;
                    height: auto;
                }
            }
        }
    }
    .instructions{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        .instruction{
            width: 53.6rem;
            border-radius: 3rem;
            border: 1px solid #EFEFEF;
            padding: 4.7rem;
            margin-top: 1.4rem;

            &:not(:last-child){
                margin-right: 1.4rem;
                @media only screen and (max-width: $bp-small){
                    margin-right: unset;
                }
            }

            @media only screen and (max-width: $bp-large){
                &:first-child{
                    margin-right: 1.4rem;
                    @media only screen and (max-width: $bp-small){
                        margin-right: unset;
                    }
                }
                margin-top: 1.4rem;
                max-width: 80vw;
            }
            @media only screen and (max-width: $bp-small){
                max-width: 90vw;
                padding: 3rem;
            }
            .icon-holder{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                img{

                }
            }
            .heading-holder{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .link{
                    text-decoration: none;
                }
                .action{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    span{
                        color: $callToAction;
                        font-family: 'Open Sans', sans-serif;
                        font-size: 1.5rem;
                        font-weight: 700;
                        line-height: 2rem;
                        text-transform: uppercase;
                    }
                    .arrow__icon{
                        color: $callToAction;
                        margin-left: 0.5rem;
                        transform: scale(1.5);
                    }
                }
            }
            .underline{
                margin: unset;
                margin-left: 0;
                margin-top: 1.4rem;
                margin-bottom: 2.5rem;
            }
            p{
                font-family: 'Poppins', sans-serif;
                color: #131F4AB9;
                font-size: 1.4rem;
                line-height: 2.8rem;
            }

            h4{
                font-size: 2rem;
                font-family: 'Poppins', sans-serif;
                font-weight: 700;
                color: $headingColor;
            }

            img{
                height: 6.7rem;
                width: 6.7rem;
            }
            .step-number{
                font-size: 7.8rem;
                font-family: 'Poppins', sans-serif;
                font-weight: 700;
                color: $headingColor;
            }
        }
    }

}