$headingSize : 6rem;
$taglineSize: 2.5rem;
$headingColor: #131F4A;
$callToAction: #FF3333;
$secondaryTextColor: orange;


$bp-desktop: 100em; //1600px
$bp-largest: 87.5em; //1400px
$bp-large: 68.75em; //1100px
$bp-medium: 49em; //768px
$bp-small: 33.75em //540

