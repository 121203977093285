@import "../../../containers/variables.scss";
header{
    grid-row: 1;
    grid-column: full-start / full-end;
    height: 10vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 6px 0px rgba(50, 50, 50, 0.3);
    -moz-box-shadow:    0px 0px 6px 0px rgba(50, 50, 50, 0.3);
    box-shadow:         0px 0px 6px 0px rgba(50, 50, 50, 0.3);
    padding: 0;
    z-index: 88;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header{
    // display: grid;
    // grid-template-columns: max-content 1fr;
    width: 100vw;
    display: grid;
    grid-template-rows: min-content;

    grid-template-columns: [full-start] 1fr [content-start] repeat(10, [col-start] minmax(min-content, 8vw) [col-end] ) [content-end] 1fr [full-end];

    @media only screen and (max-width: $bp-largest){
        grid-template-columns: [full-start] 1fr [content-start] repeat(4, [col-start] 22% [col-end] ) [content-end] 1fr [full-end];
    }
    @media only screen and (max-width: $bp-large){
        grid-template-columns: [full-start] 1fr [content-start] repeat(4, [col-start] 22% [col-end] ) [content-end] 1fr [full-end];
    }
}
nav{
    height: 100%;
    grid-row: 1;
    grid-column: col-start 5 / content-end;


    @media only screen and (min-width: $bp-small){
        grid-column: col-start 4 / full-end;
    }
    @media only screen and (min-width: $bp-medium){
        grid-column: col-start 2 / full-end;
    }

    @media only screen and (min-width: $bp-large){
        grid-column: col-start 2/content-end;

    }
    
    @media only screen and (min-width: $bp-largest){
        grid-column-end: content-end;
        // padding-right: 7vw;
    }


    display: flex;
    flex-direction: row-reverse;
    justify-items: end;
}