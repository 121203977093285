@import '../../containers/variables.scss';

::-webkit-scrollbar {
    width: 0.5rem;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }



.container{
    display: grid;
    overflow: hidden;
    grid-template-rows: [navbar-start]10vh [navbar-end jumbo-start] minmax(min-content, 90vh) [jumbo-end offer-start] min-content [offer-end howto-start] min-content [howto-end faq-start] min-content [faq-end footer-start]min-content[footer-end];
    grid-template-columns: [full-start] 1fr [content-start] repeat(10, [col-start] minmax(min-content, 8vw) [col-end] ) [content-end] 1fr [full-end];
    // 16.4rem
    @media only screen and (max-width: $bp-largest){
        grid-template-rows: 10vh minmax(min-content, 100vh) min-content min-content min-content;
        grid-template-columns: [full-start] 1fr [content-start] repeat(10, [col-start] minmax(min-content, 10rem) [col-end] ) [content-end] 1fr [full-end];    
    }
    @media only screen and (max-width: $bp-large){
        grid-template-rows: 10vh 45vh minmax(min-content, 45vh) min-content min-content;
        grid-template-columns: [full-start] 1fr [content-start] repeat(4, [col-start] minmax(min-content, 13rem) [col-end] ) [content-end] 1fr [full-end];
    }
    @media only screen and (max-width: $bp-small){
        grid-template-rows: 10vh 30vh min-content min-content min-content;
        grid-template-columns: [full-start] 1fr [content-start] repeat(4, [col-start] minmax(min-content, 13rem) [col-end] ) [content-end] 1fr [full-end];
    }
}
