@import '../../../containers/variables.scss';
 
    .laptop--img{
        // position: absolute;
        // z-index: 1;
        img{
            min-height: 100%;
            min-width: 100%;
        }
    }   
    .youtube--img{
        position: absolute;
        left: 60%;
        top: 25%;
        width: 10%;
        transform: scale(1.5);

        #Icon_metro-youtube-play{
            fill: $callToAction;
        }
        &:hover{
            #Icon_metro-youtube-play{
                fill: darken($color: $callToAction, $amount: 5%)
            }
        }
    }  

    // @media only screen and (min-width: $bp-small){
    //     // width: 70%;
    //     width: 60%;

    // }
    // @media only screen and (min-width: $bp-medium){
    //     // width: 70%;
    //     width: 60%;
    //     transform: translateX(-30%);

    // }
    // @media only screen and (min-width: $bp-large){
    //     width: 75%;
    // }
    // @media only screen and (min-width: $bp-largest){
    //     width: 70%;

    // }
    // @media only screen and (max-width: $bp-small){
    //     width: 90%;
    //     // transform: translateY(-40%);
    // }
    // height: auto;
