@import '../../containers/variables.scss';
.left{
    grid-row: 3;
    grid-column: full-start / col-start 8;
    background-image: url("../../assets/background.svg");
    

    @media only screen and (min-width: $bp-large){
        padding-left: 5vw;
        min-height: 50vh;
    }
    @media only screen and (min-width: $bp-medium){
        grid-row: 2;
        min-height: 50vh;
    }
    @media only screen and (max-width: $bp-largest){
        // grid-column: col-start 1 / content-end;
    }
    // @media only screen and (max-width: $bp-large){
    //     grid-row: 3;
    //     grid-column: col-start 1 / content-end;
    // }
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    @media only screen and (max-width: $bp-large){
        grid-row: 3;
        grid-column: full-start / full-end;
        flex-direction: column;
        justify-content: center;
    }

    .details{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 56rem;
        
        // margin-top: -8vh;
        @media only screen and (max-width: $bp-large){
            // transform: translateY(-25vh);
            order: 2;
            // max-width: 100vw;
            align-items: center;
        }
        @media only screen and (max-width: $bp-medium){
            // transform: translateY(-30vh);
            order: 2;
            max-width: 100vw;
            align-items: center;
        }
        @media only screen and (max-width: $bp-small){
            // transform: translateY(-15vh);
            margin-top: 50%;
            transform: translateY(-30%);
            width: 70vw;
        }
        h1{
            font-size: $headingSize;
            color: $headingColor;
            font-family: 'Poppins', sans-serif;
            font-weight: 700;
            line-height: 7rem;
            @media only screen and (max-width: $bp-large){
                font-size: 3rem;
                text-align: center;
            }
            @media only screen and (max-width: $bp-small){
                font-size: 3rem;
                line-height: 3.5rem;
            }
        }
        h3{
            margin-top: 1vh;
            color: $secondaryTextColor;
            @media only screen and (max-width: $bp-large){
                text-align: center;
            }
        }
        h4{
            font-size: $taglineSize;;
            color: $headingColor;
            font-family: 'Open Sans', sans-serif;
            font-weight: 300;
            margin-top: 1.6vh;
            @media only screen and (max-width: $bp-large){
                font-size: 2rem;
                text-align: center;
            }
            @media only screen and (max-width: $bp-small){
                text-align: center;
                padding: 0 10vw;
                font-size: 1.5rem;
                line-height: 2.4rem;
            }
        }
        p{
            margin-top: 7.3vh;

            @media only screen and (max-width: $bp-small){
                margin-top: 2.4vh;
                font-size: 1.2rem;
                line-height:1.7rem;
            }
            @media only screen and (max-width: $bp-medium){
                margin-top: 2.4vh;
                font-size: 1.2rem;
                line-height:1.7rem;
            }
            @media only screen and (min-width: $bp-medium){
                margin-top: 2.4vh;
                font-size: 1.7rem;
                line-height:2.3rem;
            }
            @media only screen and (min-width: $bp-largest){
                margin-top: 7.3vh;
                font-size: 1.7rem;
                line-height:2.3rem;
            }
        }

        .price{
            margin-top: 0.5vh;
            
            display: flex;
            flex-direction: row;
            align-items: center;
            @media only screen and (max-width: $bp-small){
                margin-top: 0.2vh;
            }
            &--real{
                color: $secondaryTextColor;
                font-size: 2.5rem;
                text-decoration: line-through;
                display: inline-block;
                margin-right: 3.6rem;

                @media only screen and (max-width: $bp-small){
                    font-size: 1.3rem;
                    line-height:2rem;
                    margin-right: 1.6rem;
                }
            }
            &--offer{
                color: $callToAction;
                font-size: 5rem;
                @media only screen and (max-width: $bp-small){
                    font-size: 1.8rem;
                    line-height:2.7rem;
                }
                display: inline-block;
            }
        }
        .button{
            background-color: $callToAction;
            color: white !important;
            text-align: center;
            padding: 1rem;
            border-radius: 3.5rem;
            border: none;
            cursor: pointer;
            width: 24.4rem;
            @media only screen and (min-width: $bp-large){
                font-size: 2rem;
                line-height: 3rem;
                font-family: 'Poppins', sans-serif;
                font-weight: 700;
                width: 35rem;                
                padding: 2rem;
            }
            margin-top: 2vh;
    
            &>a{
                color: white;
                text-decoration: none;
                font-size: 2rem;
                text-transform: uppercase;
            }
    
            &:hover{
                background-color: darken(red, 10%);
            }
        }
    }
    // .laptop{
    //     @media only screen and (max-width: $bp-large){
    //         order: 1;
    //     }
    //     position: absolute;
    //     width: 40vw;
    //     margin-left: 50%;
    //     transform:translateX(-25%) scale(1.125) rotate(0deg);
    //     // background-image: url('../../assets/laptopcircle937.png');
    //     // border: 1px solid blue;
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     background-size: contain;
    //     @media only screen and (max-width: $bp-largest){
    //         min-width: 70vh;
    //         transform: translateX(-8vw) scale(1) rotate(-10deg);
    //     }
    //     @media only screen and (max-width: $bp-large){
    //         // min-width: 60vw;
    //         min-width: 50vh;            
    //         max-width: 70vw;
    //         height: auto;
    //         transform:translate(-28vw, -90%) scale(1.5);
    //         // background-image: url('../../assets/laptopcirclesmll.png');
    //         // transform: translate(-5vh,-45vh) scale(1);
    //     }
    //     @media only screen and (max-width: $bp-medium){
    //         width: 100vw;
    //         height: auto;
    //         transform:translate(-22vw, -76%) scale(1);
    //     }
    //     @media only screen and (max-width: $bp-small){
    //         width: 70vw;
    //         height: auto;
    //         transform: translate(-28vw,-30vh) scale(1);
    //     }
    //     // .lapimg{
    //     //     // width: 78rem;
    //     //     width: 40vw;
    //     //     transform:translateX(8vw) scale(1.125) rotate(0deg);
    //     //     @media only screen and (max-width: $bp-largest){
    //     //         transform: translateX(-8vw) scale(1) rotate(-10deg);
    //     //     }
    //     //     @media only screen and (max-width: $bp-large){
    //     //         min-width: 70vw;
    //     //         max-width: 70vw;
    //     //         height: auto;
    //     //         transform: translate(-5vh,-15vh);
    //     //     }
    //     //     @media only screen and (max-width: $bp-medium){
    //     //         min-width: 80vw;
    //     //         max-width: 80vw;
    //     //         height: auto;
    //     //         transform: translate(-5vh,-25vh);
    //     //     }
    //     //     @media only screen and (max-width: $bp-small){
    //     //         min-width: 90vw;
    //     //         max-width: 90vw;
    //     //         height: auto;
    //     //         transform: translate(-5vh,-20vh);
    //     //     }
    //     // }
    //     .circle{
    //         display: none;
    //         position: absolute;
    //         z-index: 2;
    //         top: 0;
    //         left: 0;
    //         transform: scale(3) translate(28vw, -5vh);
            
    //     }
    // }

}