@import "../../../containers/variables.scss";
ul{
    list-style: none;
    list-style-position: inside;
    width: 100%;
    @media only screen and (max-width: $bp-small){
        width: 50vw;
    }
    @media only screen and (max-width: $bp-small){
        width: 50vw;
    }
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    
}